// https://stijndewitt.com/2014/01/26/enums-in-javascript/

const WindDirection = {
    N: 180,
    NE: 225,
    E: 270,
    SE: 315,
    S: 0,
    SW: 45,
    W: 90,
    NW: 135
};

export default WindDirection;
