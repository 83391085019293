const cities = [
  {
    name: "Vilnius",
    latitude: 54.6871555,
    longitude: 25.2796514
  },
  {
    name: "Kaunas",
    latitude: 54.8985207,
    longitude: 23.9035965
  },
  {
    name: "Klaipėda",
    latitude: 55.7032948,
    longitude: 21.1442795
  },
  {
    name: "Šiauliai",
    latitude: 55.9349085,
    longitude: 23.3136823
  },
  {
    name: "Panevėžys",
    latitude: 55.7347915,
    longitude: 24.3574774
  },
  {
    name: "Akmenė",
    latitude: 56.2455029,
    longitude: 22.7471169
  },
  {
    name: "Alytus",
    latitude: 54.3962938,
    longitude: 24.0458761
  },
  {
    name: "Anykščiai",
    latitude: 55.5291020,
    longitude: 25.1131859
  },
  {
    name: "Ariogala",
    latitude: 55.2615080,
    longitude: 23.4777328
  },
  {
    name: "Baltoji Vokė",
    latitude: 54.4539450,
    longitude: 25.1225740
  },
  {
    name: "Birštonas",
    latitude: 54.6065509,
    longitude: 24.0347890
  },
  {
    name: "Biržai",
    latitude: 56.2017719,
    longitude: 24.7560118
  },
  {
    name: "Daugai",
    latitude: 54.3612870,
    longitude: 24.3453510
  },
  {
    name: "Druskininkai",
    latitude: 54.0049069,
    longitude: 23.9864279
  },
  {
    name: "Dūkštas",
    latitude: 55.5205249,
    longitude: 26.3208829
  },
  {
    name: "Dusetos",
    latitude: 55.7485299,
    longitude: 25.8461059
  },
  {
    name: "Eišiškės",
    latitude: 54.1742519,
    longitude: 24.9993690
  },
  {
    name: "Elektrėnai",
    latitude: 54.7858116,
    longitude: 24.6624699
  },
  {
    name: "Ežerėlis",
    latitude: 54.8857659,
    longitude: 23.6094229
  },
  {
    name: "Gargždai",
    latitude: 55.7059659,
    longitude: 21.3806819
  },
  {
    name: "Garliava",
    latitude: 54.8218989,
    longitude: 23.8730009
  },
  {
    name: "Gelgaudiškis",
    latitude: 55.0799780,
    longitude: 22.9775101
  },
  {
    name: "Grigiškės",
    latitude: 54.6717149,
    longitude: 25.1062650
  },
  {
    name: "Ignalina",
    latitude: 55.3346879,
    longitude: 26.1659160
  },
  {
    name: "Jieznas",
    latitude: 54.6003320,
    longitude: 24.1765039
  },
  {
    name: "Jonava",
    latitude: 55.0727242,
    longitude: 24.2793337
  },
  {
    name: "Joniškėlis",
    latitude: 56.0209539,
    longitude: 24.1741250
  },
  {
    name: "Joniškis",
    latitude: 56.2360730,
    longitude: 23.6136579
  },
  {
    name: "Jurbarkas",
    latitude: 55.0774070,
    longitude: 22.7419569
  },
  {
    name: "Kaišiadorys",
    latitude: 54.8588669,
    longitude: 24.4277929
  },
  {
    name: "Kalvarija",
    latitude: 54.4156509,
    longitude: 23.2236948
  },
  {
    name: "Kavarskas",
    latitude: 55.4335130,
    longitude: 24.9289069
  },
  {
    name: "Kazlų Rūda",
    latitude: 54.7483950,
    longitude: 23.4910539
  },
  {
    name: "Kėdainiai",
    latitude: 55.2881309,
    longitude: 23.9577279
  },
  {
    name: "Kelmė",
    latitude: 55.6299189,
    longitude: 22.9439299
  },
  {
    name: "Kybartai",
    latitude: 54.6394340,
    longitude: 22.7680271
  },
  {
    name: "Kretinga",
    latitude: 55.8838420,
    longitude: 21.2350919
  },
  {
    name: "Kudirkos Naumiestis",
    latitude: 54.7749900,
    longitude: 22.8665119
  },
  {
    name: "Kupiškis",
    latitude: 55.8388390,
    longitude: 24.9801480
  },
  {
    name: "Kuršėnai",
    latitude: 55.9975250,
    longitude: 22.9367269
  },
  {
    name: "Lazdijai",
    latitude: 54.2343267,
    longitude: 23.5156505
  },
  {
    name: "Lentvaris",
    latitude: 54.6474359,
    longitude: 25.0434900
  },
  {
    name: "Linkuva",
    latitude: 56.0880979,
    longitude: 23.9698300
  },
  {
    name: "Marijampolė",
    latitude: 54.5553929,
    longitude: 23.3626146
  },
  {
    name: "Mažeikiai",
    latitude: 56.3092439,
    longitude: 22.3414680
  },
  {
    name: "Molėtai",
    latitude: 55.2265309,
    longitude: 25.4180011
  },
  {
    name: "Naujoji Akmenė",
    latitude: 56.3168140,
    longitude: 22.9038610
  },
  {
    name: "Nemenčinė",
    latitude: 54.8462140,
    longitude: 25.4718809
  },
  {
    name: "Neringa",
    latitude: 55.4572403,
    longitude: 21.0839005
  },
  {
    name: "Obeliai",
    latitude: 55.9436069,
    longitude: 25.8037330
  },
  {
    name: "Pabradė",
    latitude: 54.9804700,
    longitude: 25.7613712
  },
  {
    name: "Pagėgiai",
    latitude: 55.1343119,
    longitude: 21.9046810
  },
  {
    name: "Pakruojis",
    latitude: 55.9825873,
    longitude: 23.8584483
  },
  {
    name: "Palanga",
    latitude: 55.9201980,
    longitude: 21.0677614
  },
  {
    name: "Pandėlys",
    latitude: 56.0283700,
    longitude: 25.2321938
  },
  {
    name: "Panemunė",
    latitude: 55.0894399,
    longitude: 21.9021550
  },
  {
    name: "Pasvalys",
    latitude: 56.0604619,
    longitude: 24.3962910
  },
  {
    name: "Plungė",
    latitude: 55.9107840,
    longitude: 21.8454069
  },
  {
    name: "Priekulė",
    latitude: 55.5486109,
    longitude: 21.3255140
  },
  {
    name: "Prienai",
    latitude: 54.6383580,
    longitude: 23.9468009
  },
  {
    name: "Radviliškis",
    latitude: 55.8108399,
    longitude: 23.5464870
  },
  {
    name: "Ramygala",
    latitude: 55.5131559,
    longitude: 24.3005799
  },
  {
    name: "Raseiniai",
    latitude: 55.3819499,
    longitude: 23.1156129
  },
  {
    name: "Rietavas",
    latitude: 55.7245520,
    longitude: 21.9333949
  },
  {
    name: "Rokiškis",
    latitude: 55.9555039,
    longitude: 25.5859249
  },
  {
    name: "Rūdiškės",
    latitude: 54.5167550,
    longitude: 24.8249171
  },
  {
    name: "Salantai",
    latitude: 56.0560820,
    longitude: 21.5699818
  },
  {
    name: "Seda",
    latitude: 56.1716280,
    longitude: 22.0874090
  },
  {
    name: "Simnas",
    latitude: 54.3810979,
    longitude: 23.6472419
  },
  {
    name: "Skaudvilė",
    latitude: 55.4070969,
    longitude: 22.5818781
  },
  {
    name: "Skuodas",
    latitude: 56.2702169,
    longitude: 21.5214331
  },
  {
    name: "Smalininkai",
    latitude: 55.0699519,
    longitude: 22.5353600
  },
  {
    name: "Subačius",
    latitude: 55.7677230,
    longitude: 24.7502140
  },
  {
    name: "Šakiai",
    latitude: 54.9526710,
    longitude: 23.0480199
  },
  {
    name: "Šalčininkai",
    latitude: 54.3097670,
    longitude: 25.3875640
  },
  {
    name: "Šeduva",
    latitude: 55.7479790,
    longitude: 23.7573280
  },
  {
    name: "Šilalė",
    latitude: 55.4926800,
    longitude: 22.1845559
  },
  {
    name: "Šilutė",
    latitude: 55.3504140,
    longitude: 21.4659859
  },
  {
    name: "Širvintos",
    latitude: 55.0431020,
    longitude: 24.9569810
  },
  {
    name: "Švenčionėliai",
    latitude: 55.1642220,
    longitude: 26.0029360
  },
  {
    name: "Švenčionys",
    latitude: 55.1345728,
    longitude: 26.1596885
  },
  {
    name: "Tauragė",
    latitude: 55.2503660,
    longitude: 22.2909500
  },
  {
    name: "Telšiai",
    latitude: 55.9869779,
    longitude: 22.2485535
  },
  {
    name: "Tytuvėnai",
    latitude: 55.6008330,
    longitude: 23.1858590
  },
  {
    name: "Trakai",
    latitude: 54.6379113,
    longitude: 24.9346894
  },
  {
    name: "Troškūnai",
    latitude: 55.5870550,
    longitude: 24.8731299
  },
  {
    name: "Ukmergė",
    latitude: 55.2452650,
    longitude: 24.7760749
  },
  {
    name: "Utena",
    latitude: 55.5000404,
    longitude: 25.6093850
  },
  {
    name: "Užventis",
    latitude: 55.7803100,
    longitude: 22.6597649
  },
  {
    name: "Vabalninkas",
    latitude: 55.9794220,
    longitude: 24.7506350
  },
  {
    name: "Varėna",
    latitude: 54.2203330,
    longitude: 24.5789970
  },
  {
    name: "Varniai",
    latitude: 55.7414779,
    longitude: 22.3723309
  },
  {
    name: "Veisiejai",
    latitude: 54.1012410,
    longitude: 23.6936649
  },
  {
    name: "Venta",
    latitude: "56,1953250",
    longitude: "22,6945050"
  },
  {
    name: "Viekšniai",
    latitude: 56.2329869,
    longitude: 22.5152170
  },
  {
    name: "Vievis",
    latitude: 54.7738309,
    longitude: 24.8133890
  },
  {
    name: "Vilkaviškis",
    latitude: 54.6519450,
    longitude: 23.0351550
  },
  {
    name: "Vilkija",
    latitude: 55.0397719,
    longitude: 23.5831719
  },
  {
    name: "Virbalis",
    latitude: 54.6297329,
    longitude: 22.8158809
  },
  {
    name: "Visaginas",
    latitude: 55.5972760,
    longitude: 26.4313170
  },
  {
    name: "Zarasai",
    latitude: 55.7309609,
    longitude: 26.2452950
  },
  {
    name: "Žagarė",
    latitude: 56.3558489,
    longitude: 23.2444449
  },
  {
    name: "Žiežmariai",
    latitude: 54.8002119,
    longitude: 24.4403868
  },
  {
    name: "Adutiškis",
    latitude: 55.1594200,
    longitude: 26.5846769
  },
  {
    name: "Akademija",
    latitude: 54.8973320,
    longitude: 23.8243910
  },
  {
    name: "Akademija",
    latitude: 54.8973320,
    longitude: 23.8243910
  },
  {
    name: "Alanta",
    latitude: 55.3515220,
    longitude: 25.2867740
  },
  {
    name: "Alizava",
    latitude: 55.9722139,
    longitude: 25.0006159
  },
  {
    name: "Alsėdžiai",
    latitude: 56.0310449,
    longitude: 22.0468139
  },
  {
    name: "Andrioniškis",
    latitude: 55.5978139,
    longitude: 25.0450799
  },
  {
    name: "Antalieptė",
    latitude: 55.6608300,
    longitude: 25.8640359
  },
  {
    name: "Antašava",
    latitude: 55.9055329,
    longitude: 24.8073120
  },
  {
    name: "Aukštadvaris",
    latitude: 54.5795740,
    longitude: 24.5278330
  },
  {
    name: "Babtai",
    latitude: 55.0878609,
    longitude: 23.7984099
  },
  {
    name: "Bagaslaviškis",
    latitude: 55.0720659,
    longitude: 24.7696041
  },
  {
    name: "Baisogala",
    latitude: 55.6382839,
    longitude: 23.7339130
  },
  {
    name: "Balbieriškis",
    latitude: 54.5158860,
    longitude: 23.8905028
  },
  {
    name: "Balninkai",
    latitude: 55.2932309,
    longitude: 25.1203859
  },
  {
    name: "Barstyčiai",
    latitude: 56.1608260,
    longitude: 21.8704541
  },
  {
    name: "Bartninkai",
    latitude: 54.5015740,
    longitude: 23.0258799
  },
  {
    name: "Barzdai",
    latitude: 54.7801339,
    longitude: 23.1952661
  },
  {
    name: "Batakiai",
    latitude: 55.3536470,
    longitude: 22.5126858
  },
  {
    name: "Bazilionai (Padubysys)",
    latitude: 55.7965520,
    longitude: 23.1409800
  },
  {
    name: "Betygala",
    latitude: 55.3609019,
    longitude: 23.3699159
  },
  {
    name: "Bezdonys",
    latitude: 54.8002509,
    longitude: 25.5061879
  },
  {
    name: "Butrimonys",
    latitude: 54.5009259,
    longitude: 24.2540040
  },
  {
    name: "Čedasai",
    latitude: 56.0997000,
    longitude: 25.4182489
  },
  {
    name: "Čekiškė",
    latitude: 55.1580139,
    longitude: 23.5220839
  },
  {
    name: "Darbėnai",
    latitude: 56.0232549,
    longitude: 21.2558339
  },
  {
    name: "Daugailiai",
    latitude: 55.5887579,
    longitude: 25.8343659
  },
  {
    name: "Daujėnai",
    latitude: 55.9760549,
    longitude: 24.5185049
  },
  {
    name: "Daukšiai",
    latitude: 54.5012629,
    longitude: 23.5859359
  },
  {
    name: "Debeikiai",
    latitude: 55.5818210,
    longitude: 25.3258200
  },
  {
    name: "Deltuva",
    latitude: 55.2387309,
    longitude: 24.6692109
  },
  {
    name: "Dieveniškės",
    latitude: 54.1934319,
    longitude: 25.6256319
  },
  {
    name: "Dotnuva",
    latitude: 55.3660819,
    longitude: 23.8867849
  },
  {
    name: "Dovilai",
    latitude: 55.6763110,
    longitude: 21.3600870
  },
  {
    name: "Dubingiai",
    latitude: 55.0585969,
    longitude: 25.4544090
  },
  {
    name: "Duokiškis",
    latitude: 55.7432099,
    longitude: 25.5270689
  },
  {
    name: "Eigirdžiai",
    latitude: 56.0096420,
    longitude: 22.3748390
  },
  {
    name: "Endriejavas",
    latitude: 55.6961789,
    longitude: 21.7207030
  },
  {
    name: "Eržvilkas",
    latitude: 55.2630149,
    longitude: 22.7072351
  },
  {
    name: "Gadūnavas",
    latitude: 56.0650900,
    longitude: 22.2141339
  },
  {
    name: "Gardamas",
    latitude: 55.4081139,
    longitude: 21.6588779
  },
  {
    name: "Gaurė",
    latitude: 55.2409879,
    longitude: 22.4696670
  },
  {
    name: "Geležiai",
    latitude: 55.8419249,
    longitude: 24.6998360
  },
  {
    name: "Gelvonai",
    latitude: 55.0649459,
    longitude: 24.6925319
  },
  {
    name: "Giedraičiai",
    latitude: 55.0746499,
    longitude: 25.2587638
  },
  {
    name: "Girkalnis",
    latitude: 55.3094400,
    longitude: 23.2054571
  },
  {
    name: "Gražiškiai",
    latitude: 54.4681239,
    longitude: 22.9205189
  },
  {
    name: "Grinkiškis",
    latitude: 55.5539429,
    longitude: 23.6351609
  },
  {
    name: "Griškabūdis",
    latitude: 54.8512020,
    longitude: 23.1688100
  },
  {
    name: "Gruzdžiai",
    latitude: 56.0969560,
    longitude: 23.2498019
  },
  {
    name: "Gudeliai",
    latitude: 54.5273049,
    longitude: 23.6887471
  },
  {
    name: "Gudžiūnai",
    latitude: 55.5084230,
    longitude: 23.7725849
  },
  {
    name: "Igliškėliai",
    latitude: 54.5655140,
    longitude: 23.5396051
  },
  {
    name: "Ylakiai",
    latitude: 56.2797499,
    longitude: 21.8535379
  },
  {
    name: "Janapolė",
    latitude: 55.8139510,
    longitude: 22.3805260
  },
  {
    name: "Jašiūnai",
    latitude: 54.4389589,
    longitude: 25.3131650
  },
  {
    name: "Joniškis",
    latitude: 56.2360730,
    longitude: 23.6136579
  },
  {
    name: "Josvainiai",
    latitude: 55.2463669,
    longitude: 23.8387920
  },
  {
    name: "Judrėnai",
    latitude: 55.5847469,
    longitude: 21.8042030
  },
  {
    name: "Juodupė",
    latitude: 56.0861650,
    longitude: 25.6076241
  },
  {
    name: "Jūrė",
    latitude: 54.7696309,
    longitude: 23.4879810
  },
  {
    name: "Jūžintai",
    latitude: 55.7749489,
    longitude: 25.6762700
  },
  {
    name: "Kačerginė",
    latitude: 54.9339030,
    longitude: 23.7148670
  },
  {
    name: "Kairiai",
    latitude: 55.9203279,
    longitude: 23.4336130
  },
  {
    name: "Kaltanėnai",
    latitude: 55.2521099,
    longitude: 25.9935568
  },
  {
    name: "Kaltinėnai",
    latitude: 55.5648240,
    longitude: 22.4545539
  },
  {
    name: "Kamajai",
    latitude: 55.8204589,
    longitude: 25.5069750
  },
  {
    name: "Kapčiamiestis",
    latitude: 54.0031420,
    longitude: 23.6563690
  },
  {
    name: "Karklėnai",
    latitude: 55.6593319,
    longitude: 22.5682128
  },
  {
    name: "Karmėlava",
    latitude: 54.9699060,
    longitude: 24.0605900
  },
  {
    name: "Kartena",
    latitude: 55.9190710,
    longitude: 21.4727920
  },
  {
    name: "Katyčiai",
    latitude: 55.2874669,
    longitude: 21.8292690
  },
  {
    name: "Kernavė",
    latitude: 54.8867079,
    longitude: 24.8545219
  },
  {
    name: "Keturvalakiai",
    latitude: 54.5626179,
    longitude: 23.1728719
  },
  {
    name: "Kintai",
    latitude: 55.4189859,
    longitude: 21.2645549
  },
  {
    name: "Klovainiai",
    latitude: 55.9481720,
    longitude: 23.9412850
  },
  {
    name: "Krakės",
    latitude: 55.4095649,
    longitude: 23.7285911
  },
  {
    name: "Kražiai",
    latitude: 55.6024910,
    longitude: 22.6904210
  },
  {
    name: "Krekenava",
    latitude: 55.5420259,
    longitude: 24.0960010
  },
  {
    name: "Kretingalė",
    latitude: 55.8353499,
    longitude: 21.1899101
  },
  {
    name: "Krikliniai",
    latitude: 55.9121560,
    longitude: 24.4498800
  },
  {
    name: "Krinčinas",
    latitude: 56.0844459,
    longitude: 24.5257180
  },
  {
    name: "Kriukai",
    latitude: 56.2990320,
    longitude: 23.8223440
  },
  {
    name: "Kriūkai",
    latitude: 55.0683669,
    longitude: 23.4097710
  },
  {
    name: "Krokialaukis",
    latitude: 54.4367649,
    longitude: 23.7687310
  },
  {
    name: "Krosna",
    latitude: 54.3778859,
    longitude: 23.5289309
  },
  {
    name: "Kruonis",
    latitude: 54.7561329,
    longitude: 24.2424400
  },
  {
    name: "Kruopiai",
    latitude: 56.2400050,
    longitude: 23.0101531
  },
  {
    name: "Kuktiškės",
    latitude: 55.3934339,
    longitude: 25.6806029
  },
  {
    name: "Kulautuva",
    latitude: 54.9431709,
    longitude: 23.6418399
  },
  {
    name: "Kuliai",
    latitude: 55.8050189,
    longitude: 21.6535679
  },
  {
    name: "Kupreliškis",
    latitude: 56.0398510,
    longitude: 24.9659749
  },
  {
    name: "Kurkliai",
    latitude: 55.4150290,
    longitude: 25.0612408
  },
  {
    name: "Kurtuvėnai",
    latitude: 55.8309180,
    longitude: 23.0489919
  },
  {
    name: "Kužiai",
    latitude: 55.9836949,
    longitude: 23.1400009
  },
  {
    name: "Kvėdarna",
    latitude: 55.5559469,
    longitude: 21.9928879
  },
  {
    name: "Labanoras",
    latitude: 55.2673000,
    longitude: 25.7739439
  },
  {
    name: "Laižuva",
    latitude: 56.3812900,
    longitude: 22.5646730
  },
  {
    name: "Lapės",
    latitude: 54.9735350,
    longitude: 24.0010479
  },
  {
    name: "Lauko Soda",
    latitude: 55.8925950,
    longitude: 22.2577749
  },
  {
    name: "Laukuva",
    latitude: 55.6194840,
    longitude: 22.2315438
  },
  {
    name: "Leckava",
    latitude: 56.3961330,
    longitude: 22.2377550
  },
  {
    name: "Leipalingis",
    latitude: 54.0911390,
    longitude: 23.8606250
  },
  {
    name: "Lekėčiai",
    latitude: 54.9835269,
    longitude: 23.4972589
  },
  {
    name: "Leliūnai",
    latitude: 55.4746079,
    longitude: 25.3963099
  },
  {
    name: "Lenkimai",
    latitude: 56.1916999,
    longitude: 21.3153540
  },
  {
    name: "Lyduokiai",
    latitude: 55.2654120,
    longitude: 24.9447700
  },
  {
    name: "Lyduvėnai",
    latitude: 55.5069520,
    longitude: 23.0836529
  },
  {
    name: "Lygumai",
    latitude: 56.0043790,
    longitude: 23.6572619
  },
  {
    name: "Lioliai",
    latitude: 55.5577930,
    longitude: 22.9532420
  },
  {
    name: "Liudvinavas",
    latitude: 54.4818289,
    longitude: 23.3554970
  },
  {
    name: "Lukšiai",
    latitude: 54.9498849,
    longitude: 23.1738791
  },
  {
    name: "Luokė",
    latitude: 55.8915899,
    longitude: 22.5271900
  },
  {
    name: "Maišiagala",
    latitude: 54.8721550,
    longitude: 25.0649399
  },
  {
    name: "Merkinė",
    latitude: 54.1638639,
    longitude: 24.1866581
  },
  {
    name: "Meškuičiai",
    latitude: 56.0700589,
    longitude: 23.4763069
  },
  {
    name: "Mickūnai",
    latitude: 54.7061329,
    longitude: 25.5126590
  },
  {
    name: "Mielagėnai",
    latitude: 55.2583090,
    longitude: 26.4379179
  },
  {
    name: "Miežiškiai",
    latitude: 55.6762959,
    longitude: 24.5327630
  },
  {
    name: "Mosėdis",
    latitude: 56.1657035,
    longitude: 21.5734266
  },
  {
    name: "Musninkai",
    latitude: 54.9469620,
    longitude: 24.8415250
  },
  {
    name: "Naujamiestis",
    latitude: 54.6781487,
    longitude: 25.2637949
  },
  {
    name: "Nemakščiai",
    latitude: 55.4291029,
    longitude: 22.7712679
  },
  {
    name: "Nemunaitis",
    latitude: 54.2998920,
    longitude: 24.0235160
  },
  {
    name: "Nemunėlio Radviliškis",
    latitude: 56.3966339,
    longitude: 24.7733370
  },
  {
    name: "Nerimdaičiai",
    latitude: 56.0839250,
    longitude: 22.4119641
  },
  {
    name: "Nevarėnai",
    latitude: 56.1055589,
    longitude: 22.2868000
  },
  {
    name: "Onuškis",
    latitude: 54.4826389,
    longitude: 24.5923840
  },
  {
    name: "Pabaiskas",
    latitude: 55.1661029,
    longitude: 24.7660250
  },
  {
    name: "Pabiržė",
    latitude: 56.1888609,
    longitude: 24.6332139
  },
  {
    name: "Pagiriai",
    latitude: 54.5850209,
    longitude: 25.1922619
  },
  {
    name: "Pagramantis",
    latitude: 55.3656230,
    longitude: 22.2327260
  },
  {
    name: "Pajūris",
    latitude: 55.4487849,
    longitude: 22.0280239
  },
  {
    name: "Pakuonis",
    latitude: 54.7252249,
    longitude: 24.0533900
  },
  {
    name: "Palėvenė",
    latitude: 55.8030540,
    longitude: 24.8796139
  },
  {
    name: "Palonai",
    latitude: 55.6440999,
    longitude: 23.8241709
  },
  {
    name: "Panemunėlis",
    latitude: 55.9149439,
    longitude: 25.4518718
  },
  {
    name: "Panemunis",
    latitude: 56.0618859,
    longitude: 25.2852420
  },
  {
    name: "Panoteriai",
    latitude: 55.1974760,
    longitude: 24.4329661
  },
  {
    name: "Papilė",
    latitude: 56.1513520,
    longitude: 22.7937220
  },
  {
    name: "Papilys",
    latitude: 56.1120909,
    longitude: 25.0066720
  },
  {
    name: "Pašilė",
    latitude: 55.6087370,
    longitude: 22.5367071
  },
  {
    name: "Pašušvys",
    latitude: 55.5833750,
    longitude: 23.5966439
  },
  {
    name: "Pašvitinys",
    latitude: 56.1597069,
    longitude: 23.8159060
  },
  {
    name: "Pavandenė",
    latitude: 55.7711760,
    longitude: 22.4851090
  },
  {
    name: "Pernarava",
    latitude: 55.2660059,
    longitude: 23.6424789
  },
  {
    name: "Pikeliai",
    latitude: 56.4169339,
    longitude: 22.1101068
  },
  {
    name: "Pilviškiai",
    latitude: 54.7179190,
    longitude: 23.2255960
  },
  {
    name: "Plateliai",
    latitude: 56.0431439,
    longitude: 21.8161360
  },
  {
    name: "Plikiai",
    latitude: 55.7898569,
    longitude: 21.2786970
  },
  {
    name: "Pociūnėliai",
    latitude: 55.5696991,
    longitude: 23.8731990
  },
  {
    name: "Pumpėnai",
    latitude: 55.9349699,
    longitude: 24.3389720
  },
  {
    name: "Punia",
    latitude: 54.5114900,
    longitude: 24.1031480
  },
  {
    name: "Pušalotas",
    latitude: 55.9306440,
    longitude: 24.2394631
  },
  {
    name: "Raguva",
    latitude: 55.5655440,
    longitude: 24.6227389
  },
  {
    name: "Raudonė",
    latitude: 55.0999909,
    longitude: 23.1325190
  },
  {
    name: "Rimšė",
    latitude: 55.5218569,
    longitude: 26.4425199
  },
  {
    name: "Rozalimas",
    latitude: 55.8926900,
    longitude: 23.8849019
  },
  {
    name: "Rudamina",
    latitude: 54.5920089,
    longitude: 25.3481338
  },
  {
    name: "Rukla",
    latitude: 55.0523819,
    longitude: 24.3786319
  },
  {
    name: "Rumšiškės",
    latitude: 54.8679959,
    longitude: 24.2195241
  },
  {
    name: "Rusnė",
    latitude: 55.2986480,
    longitude: 21.3723221
  },
  {
    name: "Salakas",
    latitude: 55.5802459,
    longitude: 26.1336800
  },
  {
    name: "Salamiestis",
    latitude: 55.9429910,
    longitude: 24.8773439
  },
  {
    name: "Saldutiškis",
    latitude: 55.3500799,
    longitude: 25.8128621
  },
  {
    name: "Saločiai",
    latitude: 56.2279172,
    longitude: 24.4109209
  },
  {
    name: "Salos",
    latitude: 55.8128740,
    longitude: 25.3681619
  },
  {
    name: "Sasnava",
    latitude: 54.6497690,
    longitude: 23.4623559
  },
  {
    name: "Seirijai",
    latitude: 54.2289360,
    longitude: 23.8173449
  },
  {
    name: "Semeliškės",
    latitude: 54.6659879,
    longitude: 24.6592871
  },
  {
    name: "Senasis Subačius",
    latitude: 55.7329610,
    longitude: 24.7808000
  },
  {
    name: "Seredžius",
    latitude: 55.0801090,
    longitude: 23.4149770
  },
  {
    name: "Sidabravas",
    latitude: 55.6963520,
    longitude: 23.9299429
  },
  {
    name: "Siesikai",
    latitude: 55.2932049,
    longitude: 24.5031840
  },
  {
    name: "Sintautai",
    latitude: 54.8782700,
    longitude: 22.9901391
  },
  {
    name: "Skaistgirys",
    latitude: 56.3091320,
    longitude: 23.3908371
  },
  {
    name: "Skapiškis",
    latitude: 55.8945720,
    longitude: 25.2024959
  },
  {
    name: "Skiemonys",
    latitude: 55.4185730,
    longitude: 25.2738670
  },
  {
    name: "Smilgiai",
    latitude: 55.8018159,
    longitude: 24.0130879
  },
  {
    name: "Stakiai",
    latitude: 55.1789870,
    longitude: 23.0863220
  },
  {
    name: "Sudeikiai",
    latitude: 55.5816540,
    longitude: 25.6831849
  },
  {
    name: "Surdegis",
    latitude: 55.6691020,
    longitude: 24.8151620
  },
  {
    name: "Surviliškis",
    latitude: 55.4481429,
    longitude: 24.0347489
  },
  {
    name: "Suvainiškis",
    latitude: 56.1568979,
    longitude: 25.2784359
  },
  {
    name: "Svėdasai",
    latitude: 55.6835429,
    longitude: 25.3665631
  },
  {
    name: "Šakyna",
    latitude: 56.1788260,
    longitude: 23.1249698
  },
  {
    name: "Šaukėnai",
    latitude: 55.8107669,
    longitude: 22.8849760
  },
  {
    name: "Šaukotas",
    latitude: 55.5796430,
    longitude: 23.4150081
  },
  {
    name: "Šeštokai",
    latitude: 54.3586030,
    longitude: 23.4413610
  },
  {
    name: "Šešuoliai",
    latitude: 55.1775299,
    longitude: 24.9701360
  },
  {
    name: "Šėta",
    latitude: 55.2804329,
    longitude: 24.2534581
  },
  {
    name: "Šiaulėnai",
    latitude: 55.6792040,
    longitude: 23.3994030
  },
  {
    name: "Šilai",
    latitude: 55.4925069,
    longitude: 24.6063269
  },
  {
    name: "Šiluva",
    latitude: 55.5308540,
    longitude: 23.2262230
  },
  {
    name: "Šimkaičiai",
    latitude: 55.2278459,
    longitude: 22.9964290
  },
  {
    name: "Šimonys",
    latitude: 55.7384330,
    longitude: 25.1419619
  },
  {
    name: "Šumskas",
    latitude: 54.6088049,
    longitude: 25.7224449
  },
  {
    name: "Šunskai",
    latitude: 54.6420909,
    longitude: 23.3270449
  },
  {
    name: "Švėkšna",
    latitude: 55.5147689,
    longitude: 21.6256700
  },
  {
    name: "Šventežeris",
    latitude: 54.2435139,
    longitude: 23.6478299
  },
  {
    name: "Taujėnai",
    latitude: 55.3892769,
    longitude: 24.7614549
  },
  {
    name: "Tauragnai",
    latitude: 55.4443530,
    longitude: 25.8174459
  },
  {
    name: "Teneniai",
    latitude: 55.4626940,
    longitude: 21.9108100
  },
  {
    name: "Tirkšliai",
    latitude: 56.2629470,
    longitude: 22.3095180
  },
  {
    name: "Tyruliai",
    latitude: 55.7724620,
    longitude: 23.3462130
  },
  {
    name: "Traupis",
    latitude: 55.5114990,
    longitude: 24.7490179
  },
  {
    name: "Tryškiai",
    latitude: 56.0560010,
    longitude: 22.5825729
  },
  {
    name: "Truskava",
    latitude: 55.4311070,
    longitude: 24.2250898
  },
  {
    name: "Turmantas",
    latitude: 55.6942159,
    longitude: 26.4599950
  },
  {
    name: "Tverai",
    latitude: 55.7319790,
    longitude: 22.1476680
  },
  {
    name: "Tverečius",
    latitude: 55.3116770,
    longitude: 26.6023000
  },
  {
    name: "Ubiškė",
    latitude: 55.9876640,
    longitude: 22.4998939
  },
  {
    name: "Upyna",
    latitude: 55.4479980,
    longitude: 22.4459809
  },
  {
    name: "Užpaliai",
    latitude: 55.6380780,
    longitude: 25.5822479
  },
  {
    name: "Vadaktai",
    latitude: 55.6348180,
    longitude: 23.9907289
  },
  {
    name: "Vadokliai",
    latitude: 55.4966329,
    longitude: 24.4619178
  },
  {
    name: "Vadžgirys",
    latitude: 55.2542029,
    longitude: 22.9209789
  },
  {
    name: "Vainutas",
    latitude: 55.3655700,
    longitude: 21.8679621
  },
  {
    name: "Valkininkai",
    latitude: 54.3583279,
    longitude: 24.8408830
  },
  {
    name: "Vandžiogala",
    latitude: 55.1173280,
    longitude: 23.9586390
  },
  {
    name: "Vaškai",
    latitude: 56.1688080,
    longitude: 24.2110499
  },
  {
    name: "Veiveriai",
    latitude: 54.7655770,
    longitude: 23.7231200
  },
  {
    name: "Veiviržėnai",
    latitude: 55.6006909,
    longitude: 21.5968539
  },
  {
    name: "Veliuona",
    latitude: 55.0812220,
    longitude: 23.2670669
  },
  {
    name: "Vepriai",
    latitude: 55.1511709,
    longitude: 24.5748450
  },
  {
    name: "Vėžaičiai",
    latitude: 55.7174719,
    longitude: 21.4712800
  },
  {
    name: "Vidiškiai",
    latitude: 55.3065649,
    longitude: 24.8638239
  },
  {
    name: "Viduklė",
    latitude: 55.4034649,
    longitude: 22.8980799
  },
  {
    name: "Viešintos",
    latitude: 55.6906699,
    longitude: 24.9920520
  },
  {
    name: "Viešvilė",
    latitude: 55.0714300,
    longitude: 22.3863429
  },
  {
    name: "Vilkyškiai",
    latitude: 55.1187160,
    longitude: 22.1291189
  },
  {
    name: "Vištytis",
    latitude: 54.4555869,
    longitude: 22.7145720
  },
  {
    name: "Vyžuonos",
    latitude: 55.5823629,
    longitude: 25.4944080
  },
  {
    name: "Zapyškis",
    latitude: 54.9269270,
    longitude: 23.6641179
  },
  {
    name: "Zibalai",
    latitude: 55.0856369,
    longitude: 24.9933780
  },
  {
    name: "Žaiginys",
    latitude: 55.4920519,
    longitude: 23.3342209
  },
  {
    name: "Žalpiai",
    latitude: 55.4792240,
    longitude: 22.8606560
  },
  {
    name: "Žarėnai",
    latitude: 55.8390120,
    longitude: 22.2043270
  },
  {
    name: "Žasliai",
    latitude: 54.8653129,
    longitude: 24.5885540
  },
  {
    name: "Žeimelis",
    latitude: 56.2729439,
    longitude: 23.9963910
  },
  {
    name: "Žeimiai",
    latitude: 55.1793649,
    longitude: 24.2218839
  },
  {
    name: "Želva",
    latitude: 53.1481284,
    longitude: 24.8164675
  },
  {
    name: "Žemaičių Kalvarija",
    latitude: 56.1102079,
    longitude: 22.0094271
  },
  {
    name: "Žemaičių Naumiestis",
    latitude: 55.3587849,
    longitude: 21.7034230
  },
  {
    name: "Žemaitkiemis",
    latitude: 55.3053490,
    longitude: 24.9757859
  },
  {
    name: "Žemoji Panemunė",
    latitude: 55.0450569,
    longitude: 23.4680419
  },
  {
    name: "Židikai",
    latitude: 56.3219220,
    longitude: 22.0127950
  },
  {
    name: "Žygaičiai",
    latitude: 55.3128819,
    longitude: 22.0311590
  },
  {
    name: "Žvingiai",
    latitude: 55.4005760,
    longitude: 22.0206568
  },
  {
    name: "Žvirgždaičiai",
    latitude: 54.7923670,
    longitude: 23.0306708
  }
];

export default cities;
