import React from 'react';
import './arrow.css';

function Arrow(props) {
    return (
        <div className='arrow-icon baseline'>
            <svg version='1.1' className='arrow' viewBox='0 0 100 100'>
                <g transform={'rotate(' + props.direction + ',50,50) translate(0,5)'}>
                    <path d='m50,0 -20,30 16,-3 -3,63 14,0 -3,-63 16,3 -20,-30z' strokeWidth='0'></path>
                </g>
            </svg>
        </div>
    );
}

export default Arrow;