function nearestWsToLocation(lat, lon, wsList) {
  const targetLocation = {
    latitude: lat, longitude: lon
  };

  function vectorDistance(dx, dy) {
    return Math.sqrt(dx * dx + dy * dy);
  }

  function locationDistance(location1, location2) {
    var dx = location1.latitude - location2.latitude,
      dy = location1.longitude - location2.longitude;
    return vectorDistance(dx, dy);
  }

  return wsList.reduce(function (prev, curr) {
    var prevDistance = locationDistance(targetLocation, prev),
      currDistance = locationDistance(targetLocation, curr);
    return (prevDistance < currDistance) ? prev : curr;
  });
}

export { nearestWsToLocation };