import React from 'react';
import { VictoryChart, VictoryAxis, VictoryArea, VictoryStack } from 'victory';
import Arrow from './Arrow';
import { formatTimeLabels, formatFloatLabels } from '../utils/chartUtils';
import './WindDisplay.css';

// Graph idea
// https://www.meteobridge.com/wiki/index.php/PRO_Graphs

function WindDisplay(props) {
  return (
    <div className='displayBlock'>
      <div className='pure-u-1-3 bigMetricBox'>
        <span className='bigMetric windAvg'>{props.weatherData.windSpeedAvg.toFixed(1)} m/s</span><br/>
        <span className='description'>Vėjo greitis</span>
      </div>

      <div className='pure-u-1-3 bigMetricBox'>
        <span className='bigMetric windGusts'>{props.weatherData.windSpeedMax.toFixed(1)} m/s</span><br/>
        <span className='description'>Vėjo gūsiai</span>
      </div>

      <div className='pure-u-1-3 bigMetricBox'>
        <span className='bigMetric windDirection'><Arrow direction={props.weatherData.windDirection} /></span><br/>
        <span className='description'>Vėjo kryptis</span>
      </div>

      <div className='pure-u-1-1 chartContainer'>
        <VictoryChart height={150} padding={{ top: 5, left: 28, bottom: 20, right: 5 }}
          scale={{ x: 'time', y: 'linear' }} minDomain={{y: 0}} domainPadding={{y: [5, 5]}}>

          <VictoryAxis style={{ grid: { stroke: 'transparent' }, ticks: { stroke: 'grey', size: 5 }, tickLabels: { padding: 0 }}}
            tickFormat={formatTimeLabels} tickCount={3} />
          <VictoryAxis dependentAxis style={{ grid: { stroke: '#dedede', strokeDasharray: "5 5" }, tickLabels: { padding: 3 }}}
            tickFormat={formatFloatLabels} />

          <VictoryStack>
            <VictoryArea data={props.weatherData.historicalData} sortKey='timestamp'
              x={(d) => new Date(d.timestamp * 1000)} y='windSpeedAvg' interpolation='monotoneX'
              style={{ data: { stroke: '#177250', fill: '#21a372', opacity: 0.8 } }} />
            <VictoryArea data={props.weatherData.historicalData} sortKey='timestamp'
              x={(d) => new Date(d.timestamp * 1000)} y={(d) => d.windSpeedMax - d.windSpeedAvg} interpolation='monotoneX'
              style={{ data: { stroke: '#fabb09', fill: '#fcd669', opacity: 0.8 } }} />
          </VictoryStack>
        </VictoryChart>
      </div>
    </div>
  );
}

export default WindDisplay;