import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import Select from 'react-select';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/lt'
import GridLoader from 'react-spinners/GridLoader';

import TemperatureDisplay from './components/TemperatureDisplay';
import WindDisplay from './components/WindDisplay';
import PrecipitationDisplay from './components/PrecipitationDisplay';
import InfoDisplay from './components/InfoDisplay';

import cities from './models/cities';
import { getWeatherData, getWsList } from './models/wsdata';
import { nearestWsToLocation } from './utils/appUtils';

import { ReactComponent as IconGpsOff } from './icons/gps_off.svg';
import { ReactComponent as IconThermometer } from './icons/thermometer.svg';
import { ReactComponent as IconRefresh } from './icons/refresh.svg';

import './App.css';

if (window.location.hostname !== 'localhost') {
  ReactGA.initialize('UA-164623868-1');
  ReactGA.pageview('/');
}

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('lt-LT');

const locationSelectorData = cities.map((city) => {
  return {
    label: city.name,
    value: city
  };
});

if (navigator.geolocation) {
  locationSelectorData.unshift({
    label: "Dabartinė vieta",
    value: {
      currentPosition: true
    }
  });
}

const AppState = {
  LoadingData: 1,
  WaitingForLocation: 2,
  LocationError: 3,
  DisplayingData: 4,
  OtherError: 5
};

function App() {
  const [appState, setAppState] = useState();
  const [wsList, setWsList] = useState();
  const [currentPosition, setCurrentPosition] = useState();
  const [geolocationError, setGeolocationError] = useState();
  const [selectedLocation, setSelectedLocation] = useState(
    JSON.parse(localStorage.getItem('selectedLocation')) || locationSelectorData[0]
  );
  const [weatherData, setWeatherData] = useState();
  const [refreshTrigger, setRefreshTrigger] = useState();

  function onLocationSelectorChange(value, action) {
    if (action.action === 'select-option') {
      setSelectedLocation(value);
      ReactGA.event({category: 'Navigation', action: 'Selected Location'});
    }
  }

  function onRefreshClick(e) {
    setRefreshTrigger(e.timeStamp);
    ReactGA.event({category: 'Navigation', action: 'Refresh'});
  }

  useEffect(() => {
    async function loadWsList() {
      const list = await getWsList();
      setWsList(list);
    }
    loadWsList();
  }, []);

  useEffect(() => {
    if (geolocationError) {
      setAppState(AppState.LocationError);
      ReactGA.event({category: 'User', action: 'Geolocation Error'});
    }
  }, [geolocationError]);

  useEffect(() => {
    localStorage.setItem('selectedLocation', JSON.stringify(selectedLocation));

    if (selectedLocation.value.currentPosition) {
      if (!navigator.geolocation) {
        setAppState(AppState.LocationError);
      } else {
        navigator.geolocation.getCurrentPosition(setCurrentPosition, setGeolocationError);
        setAppState(AppState.WaitingForLocation);
      }
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (!selectedLocation || !wsList) {
      return;
    }

    let latitude, longitude;
    if (selectedLocation.value.currentPosition) {
      if (!currentPosition) {
        return;
      } else {
        latitude = currentPosition.coords.latitude;
        longitude = currentPosition.coords.longitude;
      }
    } else {
      latitude = selectedLocation.value.latitude;
      longitude = selectedLocation.value.longitude;
    }

    const nearestWs = nearestWsToLocation(latitude, longitude, wsList);

    setAppState(AppState.LoadingData);
    async function getData() {
      const data = await getWeatherData(nearestWs.id);
      data.lastUpdatedText = timeAgo.format(new Date(data.lastUpdated * 1000));
      setWeatherData(data);
      setAppState(AppState.DisplayingData);
    }
    getData();
  }, [wsList, selectedLocation, currentPosition, refreshTrigger]);

  return (
    <div className='App'>
      <div className='pure-g'>
        <div className='pure-u-1 pure-u-md-6-24'>
          <div className='logoBox'>
            <IconThermometer /><span className='logoText'>Už&nbsp;lango</span>
          </div>
        </div>

        <div className='pure-u-1 pure-u-md-10-24'/>

        <div className='pure-u-1 pure-u-md-8-24 locationBox'>
          <div className='refresh'>
            <IconRefresh onClick={onRefreshClick}/>
          </div>
          <div className='locationSelector'>
            <Select options={locationSelectorData} defaultValue={selectedLocation} onChange={onLocationSelectorChange} />
          </div>
        </div>

        {appState === AppState.DisplayingData && (
          <div>
            <div className='pure-u-1 pure-u-md-12-24'>
              <TemperatureDisplay weatherData={weatherData} />
            </div>

            <div className='pure-u-1 pure-u-md-12-24'>
              <WindDisplay weatherData={weatherData} />
            </div>

            <div className='pure-u-1 pure-u-md-12-24'>
              <PrecipitationDisplay weatherData={weatherData} />
            </div>

            <div className='pure-u-1 pure-u-md-12-24'>
              <InfoDisplay weatherData={weatherData} />
            </div>
          </div>
        )}

        {appState === AppState.LoadingData && (
          <div className='pure-u-1-1 loader'>
            <GridLoader css={{ display: 'block', margin: '0 auto' }} color='#32325d' />
            <p>Kraunasi...</p>
          </div>
        )}

        {appState === AppState.WaitingForLocation && (
          <div className='pure-u-1-1 loader'>
            <GridLoader css={{ display: 'block', margin: '0 auto' }} color='#32325d'/>
            <p>Nustatoma buvimo vieta...</p>
          </div>
        )}

        {appState === AppState.LocationError && (
          <div className='pure-u-1-1 geolocationError'>
            <IconGpsOff />
            <p>Nepavyko nustatyti buvimo vietos, bet galite pasirinkti artimiausią vietovę iš sąrašo.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
