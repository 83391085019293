function formatTimeLabels(date) {
  const options = {
    hour: "2-digit", minute: "2-digit"
  };
  return date.toLocaleTimeString("lt-LT", options);
}

function formatFloatLabels(value) {
  return parseFloat(value).toLocaleString("lt-LT", { maximumFractionDigits: 1 })
}

export { formatTimeLabels, formatFloatLabels };