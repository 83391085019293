import React from 'react';
import { ReactComponent as IconQuestion } from '../icons/question.svg';

import './InfoDisplay.css';

function InfoDisplay(props) {
  return (
    <div className='aboutBlock displayBlock'>
      <div className='about'>
        <IconQuestion /> Apie svetainę
      </div>
      <p>Matavimai atlikti {props.weatherData.lastUpdatedText}.</p>
      <p>Svetainėje pateikiama faktinė orų informaciją.</p>
      <p>Duomenų šaltinis <a href="https://eismoinfo.lt">eismoinfo.lt</a><br />
        Stotelė "{props.weatherData.name}" (kelias {props.weatherData.roadName}, {props.weatherData.roadKm} km).
      </p>
    </div>
  );
}

export default InfoDisplay;