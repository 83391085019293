import React from 'react';
import { VictoryChart, VictoryAxis, VictoryBar } from 'victory';
import { formatTimeLabels, formatFloatLabels } from '../utils/chartUtils';
import './PrecipitationDisplay.css';

function PrecipitationDisplay(props) {
  return (
    <div className='displayBlock'>
      <div className='pure-u-1-3 bigMetricBox'>
        <span className='bigMetric precipitationType'>{props.weatherData.precipitationType}</span><br />
        <span className='description'>Krituliai</span>
      </div>

      <div className='pure-u-1-3 bigMetricBox'>
        <span className='bigMetric precipitationAmount'>{props.weatherData.precipitationAmount.toFixed(1)} mm/val</span><br />
        <span className='description'>Kritulių kiekis</span>
      </div>

      <div className='pure-u-1-3 bigMetricBox'>
        <span className='bigMetric roadCondition'>{props.weatherData.roadCondition}</span><br />
        <span className='description'>Kelio danga</span>
      </div>

      <div className='pure-u-1-1 chartContainer'>
        <VictoryChart height={150} padding={{ top: 5, left: 28, bottom: 20, right: 5 }}
          scale={{ x: 'time', y: 'linear' }} maxDomain={{ y: 1.6 }} minDomain={{ y: 0 }} domainPadding={{ x: [10, 10], y: [5, 5] }}>

          <VictoryAxis style={{ grid: { stroke: 'transparent' }, ticks: { stroke: 'grey', size: 5 }, tickLabels: { padding: 0 } }}
            tickFormat={formatTimeLabels} tickCount={3} />
          <VictoryAxis dependentAxis style={{ grid: { stroke: '#dedede', strokeDasharray: "5 5" }, tickLabels: { padding: 3 } }}
            tickFormat={formatFloatLabels} />

          <VictoryBar data={props.weatherData.historicalData} sortKey='timestamp'
            x={(d) => new Date(d.timestamp * 1000)} y='precipitationAmount'
            style={{ data: { fill: '#0984ff', opacity: 0.8 } }} />

        </VictoryChart>
      </div>
    </div>
  );
}

export default PrecipitationDisplay;