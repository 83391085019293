import axios from 'axios';
import Feels from 'feels';
import WindDirection from './WindDirection.js';

async function getWsList() {
  const result = await axios('https://eismoinfo.lt/weather-conditions-service');
  return result.data.reduce((arr, el) => {
    if (el.vejo_greitis_vidut == null || el.oro_temperatura == null ||
        el.rasos_taskas == null || el.krituliu_kiekis == null) {
      return arr;
    }
    const ws = {
      id: el.id,
      name: el.irenginys,
      roadName: el.pavadinimas,
      roadCode: el.numeris,
      roadKm: parseFloat(el.kilometras),
      latitude: parseFloat(el.lat),
      longitude: parseFloat(el.lng)
    };
    arr.push(ws);
    return arr;
  }, []);
}

async function getWeatherData(wsId) {
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  await sleep(200);
  const result = await axios('https://eismoinfo.lt/weather-conditions-retrospective?id=' + wsId + '&number=50');
  const data = result.data;

  const weatherData = {
    id: data[0].id,
    name: data[0].irenginys,
    roadName: data[0].pavadinimas,
    roadCode: data[0].numeris,
    roadKm: parseFloat(data[0].kilometras),
    latitude: parseFloat(data[0].lat),
    longitude: parseFloat(data[0].lng),
    lastUpdated: parseInt(data[0].surinkimo_data_unix),
    historicalData: []
  };

  const historyMinTime = weatherData.lastUpdated - 3 * 3600; // 3 hours
  let historyTimeLast = null;

  data.forEach((ei_data) => {
    const dataPointTime = parseInt(ei_data.surinkimo_data_unix);
    if (dataPointTime < historyMinTime) {
      return; // skip older values
    }
    if (!historyTimeLast || historyTimeLast !== dataPointTime) {
      historyTimeLast = dataPointTime;
    } else {
      return; // skip duplicate data points
    }

    const dataPoint = {
      timestamp: dataPointTime,
      temperature: parseFloat(ei_data.oro_temperatura),
      dewPoint: parseFloat(ei_data.rasos_taskas),
      windSpeedAvg: parseFloat(ei_data.vejo_greitis_vidut),
      windSpeedMax: parseFloat(ei_data.vejo_greitis_maks),
      windDirection: eiToWindDirection(ei_data.vejo_kryptis),
      precipitationType: ei_data.krituliu_tipas,
      precipitationAmount: parseFloat(ei_data.krituliu_kiekis),
      roadCondition: ei_data.kelio_danga
    };

    for (const key in dataPoint) {
      const val = dataPoint[key];
      if (typeof(val) !== "string" && isNaN(val)) {
        return;
      }
    }

    if (!isNaN(dataPoint.dewPoint)) {
      dataPoint.relativeHumidity = Feels.getRH(dataPoint.temperature, dataPoint.dewPoint, { dewPoint: true, round: true });
      dataPoint.feelsLike = Feels.AAT(dataPoint.temperature, dataPoint.windSpeedAvg, dataPoint.dewPoint, { dewPoint: true, round: false });
    }

    weatherData.historicalData.unshift(dataPoint);
  });

  const lastDataPoint = weatherData.historicalData[weatherData.historicalData.length - 1];
  for (const key in lastDataPoint) {
    weatherData[key] = lastDataPoint[key];
  }

  return weatherData;
}

function eiToWindDirection(windDirectionString) {
  if (!windDirectionString) {
    return null;
  }

  const s = windDirectionString.toLowerCase();
  switch (s) {
    case "šiaurės":
      return WindDirection.N;
    case "šiaurės rytų":
      return WindDirection.NE;
    case "rytų":
      return WindDirection.E;
    case "pietryčių":
      return WindDirection.SE;
    case "pietų":
      return WindDirection.S;
    case "pietvakarių":
      return WindDirection.SW;
    case "vakarų":
      return WindDirection.W;
    case "šiaurės vakarų":
      return WindDirection.NW;
    default:
      return null;
  }
}

export { getWeatherData, getWsList };
