import React from 'react';
import { VictoryChart, VictoryAxis, VictoryLine } from 'victory';
import { formatTimeLabels, formatFloatLabels } from '../utils/chartUtils';
import './TemperatureDisplay.css';

function TemperatureDisplay(props) {
  return (
    <div className='displayBlock'>

      <div className='pure-u-1-3 bigMetricBox'>
        <span className='bigMetric temperature' >{props.weatherData.temperature.toFixed(1)} &deg;C</span><br />
        <span className='description'>Temperatūra</span>
      </div>

      <div className='pure-u-1-3 bigMetricBox'>
        <span className='bigMetric feelsLike'>{props.weatherData.feelsLike.toFixed(1)} &deg;C</span><br />
        <span className='description'>Juntamoji<br />temperatūra</span>
      </div>

      <div className='pure-u-1-3 bigMetricBox'>
        <span className='bigMetric humidity'>{props.weatherData.relativeHumidity} %</span><br />
        <span className='description'>Santykinė<br />drėgmė</span>
      </div>

      <div className='pure-u-1-1 chartContainer'>
        <VictoryChart height={150} padding={{ top: 5, left: 28, bottom: 20, right: 5 }}
          scale={{ x: 'time', y: 'linear' }} domainPadding={{ y: [5, 5] }}>

          <VictoryAxis style={{ grid: { stroke: 'transparent' }, ticks: { stroke: 'grey', size: 5 }, tickLabels: { padding: 0 } }}
            tickFormat={formatTimeLabels} tickCount={2} />
          <VictoryAxis dependentAxis style={{ grid: { stroke: '#dedede', strokeDasharray: "5 5" }, tickLabels: { padding: 3 } }}
            tickFormat={formatFloatLabels} />

          <VictoryLine data={props.weatherData.historicalData} sortKey='timestamp'
            x={(d) => new Date(d.timestamp * 1000)} y='temperature' interpolation='monotoneX'
            style={{ data: { stroke: '#c93f21', opacity: 0.8 } }} />
          <VictoryLine data={props.weatherData.historicalData} sortKey='timestamp'
            x={(d) => new Date(d.timestamp * 1000)} y='feelsLike' interpolation='monotoneX'
            style={{ data: { stroke: '#6772e5', opacity: 0.8 } }} />
        </VictoryChart>
      </div>
    </div>
  );
}

export default TemperatureDisplay;